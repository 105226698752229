<template>
    <div class="step-2-work-provider" id="step-2-work-provider">   
        <Stepper :step="step" :numberOfSteps="4" :top="true"/>
        
        <div v-if="step === 1" id="step-1" class="card">
            <div class="card-heading">
                <p class="title">{{$t('getstarted.steps.two.workProvider.splits.one.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question alternate-name">
                        <div class="label-container">
                            <p id="name-label" :class="error('alternateName')">{{$t('alternateNameQuestion')}}</p>
                            <FormError :data="validation.alternateName" :classes="[{desktop: true}, {responsive: true}]" />
                        </div>

                        <div class="input-container">
                            <input id="name-input" :class="['input input-text', { error: validation.alternateName.error }]" v-model="data.alternateName" type="text">
                        </div>

                        <FormError :data="validation.alternateName" :classes="[{mobile: true}, {responsive: true}]" />
                    </div>
                    
                    <div class="question logo">
                        <div class="label-container">
                            <p id="logo-label" :class="error('logo_url')">{{$t('logo')}}</p>
                            <FormError :data="validation.logo_url" :classes="[{desktop: true}, {responsive: true}]" />
                        </div>
                        
                        <div class="input-container">
                            <img class="uploaded-logo" v-if="data.logo_url && data.logo_url !== 'none'" :src="data.logo_url" alt="uploaded logo">
                            <p class="no-logo-label" v-if="data.logo_url === 'none'">{{ $t('getstarted.steps.two.workProvider.splits.one.no-logo-label') }}</p>

                            <div class="cta-container">
                                <button id="upload-logo" :class="['cta cta-outline cta-slim dark']" @click="setup_upload_widget()">{{ data.logo_url ? $t('language.replace') : $t('language.upload') }}</button>
                                <button id="upload-no-logo" :class="['cta cta-outline cta-slim dark']" @click="data.logo_url = 'none'">{{$t('getstarted.steps.two.workProvider.splits.one.no-logo')}}</button>
                            </div>
                        </div>

                        <FormError :data="validation.logo_url" :classes="[{mobile: true}, {responsive: true}]" />
                    </div>
                </div>
            </div>

            <div class="card-footer with-padding">
                <button id="get_started_back_step_2" :class="['cta cta-outline dark arrow']" @click="change_current_step(-1)"><font-awesome-icon :icon="['fa', 'arrow-left']" /></button>
                <button id="get_started_next_step_2" :class="['cta cta-primary arrow']" @click="next()"><font-awesome-icon :icon="['fa', 'arrow-right']" /></button>
            </div>
        </div>
        
        <div v-if="step === 2" id="step-2" class="card">
            <div class="card-heading">
                <p class="title">{{$t('getstarted.steps.two.workProvider.splits.two.title')}}</p>
            </div>

            <div class="card-body">
                <div class="maps-autocomplete-container">
                    <div class="row">
                        <div class="input-container autocomplete-container">
                            <p>{{$t('getstarted.steps.two.workProvider.splits.two.search.title')}}</p>
                            
                            <input ref="autocomplete" 
                                id="autocomplete-input"
                                :placeholder="$t('getstarted.steps.two.workProvider.splits.two.search.placeholder')" 
                                :class="['input input-text', error('location')]"
                                onfocus="value = ''" 
                                type="text" />

                            <FormError :data="validation.location" />
                            <p v-if="!validation.location.error" class="notice">{{$t('getstarted.steps.two.workProvider.splits.two.search.addLater')}}</p>
                        </div>

                        <div class="input-container al2-container">
                            <p class="bold">{{$t('getstarted.steps.two.workProvider.splits.two.search.local')}}</p>
                            <input id="address-line-2-input" v-model="data.location.addressLine2" :class="['input input-text', { }]" type="text" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer with-padding">
                <button id="get_started_back_step_2" :class="['cta cta-outline dark arrow']" @click="step -= 1"><font-awesome-icon :icon="['fa', 'arrow-left']" /></button>
                <button id="get_started_next_step_2" :class="['cta cta-primary cta-slim']" @click="next()"><font-awesome-icon :icon="['fa', 'arrow-right']" /></button>
            </div>
        </div>

        <div v-if="step === 3" id="step-3" class="card">
            <div class="card-heading">
                <p class="title">{{$t('getstarted.steps.two.workProvider.splits.three.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="classification-container">
                        <ClassificationComponent :classification="data.classification" :validation="validation" client="work-provider_onboarding" @update="update($event)" />
                    </div>
                </div>
            </div>

            <div class="card-footer with-padding">
                <button id="get_started_back_step_2" :class="['cta cta-outline dark arrow']" @click="step -= 1"><font-awesome-icon :icon="['fa', 'arrow-left']" /></button>
                <button id="get_started_next_step_2" :class="['cta cta-primary cta-slim']" @click="next()"><font-awesome-icon :icon="['fa', 'arrow-right']" /></button>
            </div>
        </div>

        <div v-if="step === 4" id="step-4" class="card">
            <div class="card-heading">
                <p class="title">{{$t('getstarted.steps.two.workProvider.splits.four.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question allowed-contact-methods allowed-contact-methods-work-provider column">
                        <div class="label-container">
                            <p>{{$t('allowed_contact_methods.title')}}</p>
                            <p class="subtitle" v-html="$t('allowed_contact_methods.subtitle')"></p>
                        </div>

                        <div class="row">
                            <div class="input-container label-on-top">
                                <p id="phone-number-label" :class="error('phone_number')">{{ $t('allowed_contact_methods.fields.phone_number.title') }}</p>
                                <the-mask id="phone-number-input" mask="+1 (###) ###-####" v-model="data.phone_number" :class="['input input-text', error('phone_number')]" placeholder="+1 (123) 456-7890"/>
                            </div>

                            <div class="input-container label-on-top w-auto">
                                <p id="ext-label">{{ $t('allowed_contact_methods.fields.ext.title') }}</p>
                                <the-mask id="ext-input" mask="############" v-model="data.extension" :class="['input input-text w-100']"/>
                            </div>

                            <div class="input-container label-on-top">
                                <p>{{ $t('allowed_contact_methods.fields.email.title') }}</p>
                                <input id="email-input" :class="['input input-text disabled']" v-model="email" type="text">
                            </div>
                        </div>
                        
                        <FormError :data="validation.phone_number" :classes="[{desktop: true}, {responsive: true}]" />
                        
                        <div class="checkbox-container" @click="data.supports_texts = !data.supports_texts">
                            <input id="checkbox-text-message" type="checkbox" :class="[{ checked: data.supports_texts }]" v-model="data.supports_texts">
                            <p class="label">{{$t('allowed_contact_methods.text-messaging')}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer with-padding">
                <button id="get_started_back_step_2" :class="['cta cta-outline dark arrow']" @click="step -= 1"><font-awesome-icon :icon="['fa', 'arrow-left']" /></button>
                <button id="get_started_submit_step_2" :class="['cta cta-primary cta-slim', { loading: loading }]" @click="next()">Finaliser</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import { mapGetters, mapActions } from 'vuex';

import Stepper from "../../../pages/Dashboard/GetStarted/Steps/Stepper.vue";
import FormError from '../../../components/Utils/FormError.vue';
import ClassificationComponent from "../../Form/ClassificationComponent.vue";

export default {
    name: "Step2WorkProvider",

    components: {
        Stepper,
        FormError,
        ClassificationComponent
    },
    
    data() {
        return {    
            step: 1,

            data: {
                alternateName: '',
                logo_url: '',
                workProviderType: 'paid',

                phone_number: '',
                extension: '',
                supports_texts: false,

                classification: {
                    interests: [],
                    query: '',
                    all: false
                },

                location: {
                    addressLine2: '',
                }
            },

            validation: {
                alternateName: {
                    error: false,
                    name: 'alternateName',
                    key: 'errors.validation.fieldMandatory'
                },

                logo_url: {
                    error: false,
                    name: 'logo_url',
                    key: 'errors.validation.fieldMandatory'
                },

                phone_number: {
                    error: false,
                    name: 'phone_number',
                    key: 'errors.validation.fieldMandatory'
                },

                location: {
                    error: false,
                    name: 'location',
                    key: 'errors.validation.fieldMandatory'
                },

                classification: {
                    interests: {
                        error: false,
                        name: 'classification-interests',
                        key: 'errors.validation.fieldMandatory'
                    },
                },
            },

            formValidated: false,
            loading: false
        }
    },

    computed: {
        ...mapGetters([
            'context',
            'currentLanguage',
            'email',
            'users_geolocation'
        ]),

        showBackButton: function() {
            const self = this; return self.context === 'onboarding';
        }
    },

    methods: {
        ...mapActions([
            'change_current_step',
            'setToken'
        ]),

        next: function() {
            const self = this;
            self.validate_mandatory_fields(self.step);
            if(self.formValidated) { 
                if(self.step === 4) {
                    self.finalize();
                } else {
                    self.step += 1 
                    if(self.step === 2) { self.setup_autocomplete(); }
                }
            }
        },

        validate_mandatory_fields: function(step) {
            const self = this;

            self.reset_valdiation();
            if(step === 1) { self.validate_step_1();  }
            else if (step === 2) { self.validate_step_2(); }
            else if (step === 3) { self.validate_step_3(); }
            else if (step === 4) { self.validate_step_4(); }
        },

        validate_step_1: function() {
            const self = this;

            if(!self.data.alternateName) {
                self.validation.alternateName.error = true;
                self.formValidated = false;
            }
            
            if(!self.data.logo_url) {
                self.validation.logo_url.error = true;
                self.formValidated = false;
            }
        },

        validate_step_2: function() {
            const self = this;

            if(!self.data.location.addressLine1 && !self.data.location.formattedAddress) {
                self.validation.location.error = true;
                self.formValidated = false;
            }
        },

        validate_step_3: function() {
            const self = this;

            if(self.data.classification.interests.length === 0) {
                self.validation.classification.interests.error = true;
                self.formValidated = false;
            }
        },

        validate_step_4: function() {
            const self = this;

            if(!self.data.phone_number) {
                self.validation.phone_number.error = true;
                self.formValidated = false;
            }
        },

        reset_valdiation: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false
            });

            self.validation.classification.interests.error = false;
        },

        finalize: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;

                const data = {
                    alternateName: self.data.alternateName,
                    workProviderType: self.data.workProviderType,
                    logo_url: self.data.logo_url,
                    initial_location: self.data.location,
                    initial_interests: self.data.classification.interests,
                    account_type: 'free',
                    allowed_contact_methods: {
                        chat: { allowed: true },
                        email: {
                            allowed: true,
                            value: self.email,
                            externaly_validated: false
                        },
                        phone: {
                            allowed: true,
                            value: self.data.phone_number.trim(),
                            supports_texts: self.data.supports_texts,
                            externaly_validated: false,
                        },
                    },
                }

                if(self.data.extension && self.data.extension.trim() !== '') {
                    data.allowed_contact_methods.phone.extension = self.data.extension.trim();
                }
                
                self.$axios
                    .post('/work-provider', data)
                    .then(response => {
                        self.loading = false;
                        
                        if(response.status == 200) {
                            const data = response.data;

                            self.setToken(data);
                            self.$utils._navigate_to_name('dashboard');
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        setup_upload_widget: function() {
            const self = this;

            window.cloudinary.openUploadWidget({ 
                cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
                upload_preset: process.env.VUE_APP_CLOUDINARY_PRESET,
                sources: [ 'local' ],
                showAdvancedOptions: false,
                cropping: false,
                multiple: false,
                defaultSource: 'local',
                language: self.currentLanguage,
                styles: {
                    palette: {
                        link: "#FFCE1A",
                    },
                    fonts: {
                        default: {
                            active: true
                        }
                    }
                },
                text: {
                    "en": {
                        "menu": { "files": "Your logo" },
                        "local": { "browse": "Browse" }
                    },

                    "fr": {
                        "menu": { "files": "Votre logo" },
                        "local": { "browse": "Sélectionner" }
                    }
                }
            }, (error, result) => {
                if (!error && result && result.event === "success") {
                    self.data.logo_url = result.info.url;        
                }
            }).open();
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        },

        setup_autocomplete: async function() {
            const self = this;
            const loader = new Loader({
                apiKey: process.env.VUE_APP_MAPS_API_KEY,
                version: "weekly",
            });

            let center = { lat: 46.849132, lng: -71.255024 };
            if(self.users_geolocation && self.users_geolocation.lat && self.users_geolocation.long) {
                center = { lat: self.users_geolocation.lat, lng: self.users_geolocation.long };
            }

            const defaultBounds = {
                north: center.lat + 0.3,
                south: center.lat - 0.3,
                east: center.lng + 0.3,
                west: center.lng - 0.3,
            };

            const autocomplete_options = {
                types: ['geocode'],
                componentRestrictions: { country: 'ca' },
                strictBounds: false,
                bounds: defaultBounds
            };

            loader.importLibrary('places')
                .then(({Autocomplete}) => {
                    const autocomplete = new Autocomplete(
                        (this.$refs.autocomplete),
                        autocomplete_options
                    );

                    autocomplete.addListener('place_changed', () => {
                        self.format_autocomplete_response(autocomplete.getPlace());
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        update: function(data) {
            const self = this;
            
            const keys = Object.keys(data);
            keys.forEach(key => {
                if(key === 'validation') { self.validation = data[key] }
                else { self.data[key] = data[key] }
            });
        },

        format_autocomplete_response: function(place) {
            const self = this;

            const addressLine1 = place.address_components.find(component => component.types.includes('street_number')).long_name + ' ' + place.address_components.find(component => component.types.includes('route')).long_name;
            const city = place.address_components.find(component => component.types.includes('locality')).long_name;
            const province = place.address_components.find(component => component.types.includes('administrative_area_level_1')).short_name;
            const postalCode = place.address_components.find(component => component.types.includes('postal_code')).long_name;
            const country = place.address_components.find(component => component.types.includes('country')).long_name;
            
            self.data.location.addressLine1 = addressLine1;
            self.data.location.city = city;
            self.data.location.province = province;
            self.data.location.postalCode = postalCode;
            self.data.location.country = country;
            self.data.location.geocoding = {
                formattedAddress: place.formatted_address,
                geolocation: {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                }
            }
        },

        capture_query: function() {
            const self = this;
            
            if(self.$route.query && self.$route.query.step) {
                const step = parseFloat(self.$route.query.step);
                if(typeof step === 'number' && step >= 1 && step <= 4) { 
                    self.step = step 
                    if(self.step === 2) { self.setup_autocomplete(); }
                }
            }
        },
    },

    mounted() {
        const self = this;

        const cloudinary_script = document.createElement('script')
        cloudinary_script.setAttribute('src', 'https://widget.cloudinary.com/v2.0/global/all.js')
        document.head.appendChild(cloudinary_script);

        self.capture_query();
    }   
};
</script>