<template>
    <div class="step-container step-2">
        <div class="title-container">
            <h1 class="heading">{{number}}{{$t(titleLabelKey)}}</h1>
        </div>
        
        <WorkerCard         v-if="choice === 'worker'" />
        <WorkProviderCard   v-else />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import WorkerCard from '../../../../components/Cards/GetStarted/Step2WorkerCard.vue'
import WorkProviderCard from '../../../../components/Cards/GetStarted/Step2WorkProviderCard.vue'

export default {
    name: "step-2",

    components: {
        WorkerCard,
        WorkProviderCard
    },

    computed: {
        ...mapGetters([
            'choice',
            'context'
        ])
    },
    
    data() {
        return {
            titleLabelKey: '',
            subtitleLabelKey: '',
            number: ''
        }
    },

    created() {
        const self = this;

        if(self.context === 'createWorker' || self.context === 'createWorkProvider') {
            self.number = '1';
        } else {
            self.number = '2';
        }

        if(self.choice === 'worker'){
            self.titleLabelKey = 'getstarted.steps.two.worker.title';
            self.subtitleLabelKey = 'getstarted.steps.two.worker.subtitle';
        }
        
        if (self.choice === 'workProvider') {
            self.titleLabelKey = 'getstarted.steps.two.workProvider.title';
        }
    }
};
</script>