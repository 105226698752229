s<template>
    <div class="step-container step-1">
        <div class="title-container">
            <h1 class="heading">{{$t('getstarted.steps.one.title')}}</h1>
        </div>

        <div class="card">
            <div class="card-heading">
                <p class="title">{{$t('getstarted.choose.one')}}</p>
            </div>

            <div class="card-body">
                <multiselect    v-model="choice" 
                                :options="options"
                                :close-on-select="true"
                                :multiple="false"
                                :allowEmpty="false"
                                :searchable="false"
                                :class="error('choice')"

                                :placeholder="$t('getstarted.choose.one')"
                                :select-label="$t('language.multiselect.label.select')"
                                :selected-label="$t('language.multiselect.label.selected')"
                                :deselect-label="$t('language.multiselect.label.deselect')"
                                :custom-label="translateLabel"> 
                
                                <template slot="noResult">{{$t('language.multiselect.label.no_result')}}</template>
                                <template slot="noOptions">{{$t('language.multiselect.label.no_options')}}</template>
                </multiselect>  

                <FormError :data="validation.choice" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormError from '../../../../components/Utils/FormError.vue';

export default {
    name: "step-1",

    components: {
        FormError
    },

    data() {
        return {
            choice: '',
            options: ['worker', 'workProvider'],
            formValidated: false,
            
            validation: {
                choice: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            }
        }
    },

    watch: { 
        choice: function(val) {
            const self = this;
            if(val === 'worker' || val === 'workProvider') { self.next(); }
        }
    },

    methods: {
        ...mapActions([
            'change_current_step',
            'change_choice'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.validation.choice.error = false;
            self.formValidated = true;

            if(!self.choice) {
                self.validation.choice.error = true;
                self.formValidated = false;
            }
        },

        next: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.change_choice(self.choice);
                self.change_current_step(1);
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        translateLabel: function(choice) {
            const self = this;

            return self.$t(`getstarted.steps.one.${choice}`)
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    }
};
</script>