<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope">
        <Step1  v-if="currentStep === 1" />
        <Step2  v-if="currentStep === 2" />

        <div class="lb-bg"></div>
    </layout>
</template>

<script>
import cookies from 'js-cookie';
import { mapGetters, mapActions } from 'vuex';

import Layout from "../../../components/Layout.vue";

import Step1 from "./Steps/Step1.vue";
import Step2 from "./Steps/Step2.vue";

export default {
    name: "get-started",

    components: {
        Layout,
        
        Step1,
        Step2,
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        },

        context: {
            type: String,
            default: 'onboarding'
        }
    },

    data() {
        return {
            routeName: "get-started",
            classes: {
                'get-started': true
            }
        }
    },

    computed: {
        ...mapGetters([
            'currentStep',
            'onboardingProcessCompleted',
            'sign_up_choice'
        ])
    },

    methods: {
        ...mapActions([
            'change_current_step',
            'change_choice',
            'reset_onboarding'
        ])
    },

    mounted() {
        const self = this;
        self.reset_onboarding();

        if (self.onboardingProcessCompleted) {
            self.$utils._navigate_to_name('dashboard');
        }

        if(self.$route.query && self.$route.query.choice) {
            if(self.$route.query.choice === 'work') {
                self.change_choice('worker');
            } else if(self.$route.query.choice === 'hire') {
                self.change_choice('workProvider');
            }

            self.change_current_step(1);
        }else if (self.sign_up_choice) {
            if(self.sign_up_choice === 'work') {
                self.change_choice('worker');
                self.change_current_step(1);
            }   
            
            if(self.sign_up_choice === 'hire') {
                self.change_choice('workProvider');
                self.change_current_step(1);
            }
        }

        const from_retirement_calculator = cookies.get('from_retirement_calculator');
        if(from_retirement_calculator) {
            self.change_choice('worker');
            self.change_current_step(1);
        }
    }
}
</script>